import TopBar from "../components/Header/Topbar";
import Footer from "../components/Footer/Footer";
import { Container } from "reactstrap";
import HeroSection from "../components/Max-Maginze_1/HeroSection";

const Main = (props) => {
  return (
    <>
      {/* <TopBar />
      <HeroSection /> */}
      <div className="bg-white">{props.children}</div>
      {/* <Footer /> */}
    </>
  );
};

export default Main;
