import React from "react";
// import logo from "../../assets/img/max_magazne-logo2 1.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Topbar = ({ hide }) => {
  const history = useHistory();
  const handleClick = () => {
    history.push("/");
  };
  return (
    <div className="pt-sm-2" id="addPostCode">
      <div className="d-flex justify-content-start align-items-center navbar">
        {/* <img
                    src={logo}
                    alt="logo"
                    fluid
                    onClick={handleClick}
                    className="pointer logo"
                /> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          // xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          width="230px"
          height="64px"
          viewBox="0 0 558.5 158.1"
          // style="enable-background:new 0 0 558.5 158.1;"
          // xml:space="preserve"
          onClick={handleClick}
          className="pointer logo"
        >
          <ellipse
            transform="matrix(0.9397 -0.342 0.342 0.9397 -20.9091 39.5493)"
            fill="#FF5F00"
            cx="101.7"
            cy="79.1"
            rx="104.7"
            ry="75"
          />
          <path
            fill="#FFFFFF"
            d="M159.1,76.7l20.4-25.3h-20.9l-9.7,13.2L141,51.4h-20.9l19.3,25.3l-9.3,11.1l-17.3-53.5H98.4L84.3,76.9  l-4.9-25.5H64.9L54.8,78.5l-10-27.1H30.3L20,105.6h14.8l5.4-28.9l11.2,28.9h6.3l11.9-29l5.4,29l-5.6,17.1h17.1l5.8-19.3h26.2  l5.7,19.3h17l-5.5-17.1l13.9-16.9l11.8,16.9h20.7L159.1,76.7L159.1,76.7z M96.2,90.3l9.3-30.9l9.1,30.9H96.2z"
          />
          <path
            fill="#141932"
            d="M267.2,105.6h-20.9v-2.9c0.7-0.1,1.3-0.1,2-0.2c0.7-0.1,1.3-0.2,1.8-0.3c0.8-0.3,1.4-0.7,1.8-1.4  s0.6-1.5,0.6-2.6V76c0-3-0.7-5.4-2.1-7s-3.1-2.5-5.2-2.5c-1.4,0-2.8,0.2-4,0.7s-2.4,1-3.4,1.8c-0.8,0.6-1.5,1.3-2.1,2.1  c-0.6,0.7-1,1.4-1.3,1.9v24.8c0,1,0.2,1.9,0.5,2.5c0.3,0.7,0.9,1.2,1.8,1.5c0.7,0.3,1.3,0.5,1.8,0.6s1.2,0.2,2.1,0.3v2.9h-20.5v-2.9  c0.7-0.1,1.3-0.1,1.9-0.2c0.6-0.1,1.2-0.2,1.7-0.3c0.8-0.3,1.4-0.7,1.8-1.4s0.6-1.5,0.6-2.6V76c0-3-0.7-5.4-2.1-7s-3.1-2.5-5.2-2.5  c-1.4,0-2.8,0.2-4,0.7c-1.3,0.5-2.4,1.1-3.5,1.9c-0.8,0.6-1.6,1.4-2.1,2.2c-0.6,0.8-1,1.4-1.3,1.8v24.6c0,1,0.2,1.8,0.6,2.5  s1,1.2,1.8,1.5c0.6,0.3,1.2,0.5,1.9,0.7c0.6,0.1,1.4,0.2,2.1,0.3v2.9h-20.9v-2.9c0.7-0.1,1.4-0.1,2.1-0.2c0.7-0.1,1.3-0.2,1.9-0.3  c0.8-0.3,1.4-0.7,1.8-1.4s0.6-1.5,0.6-2.5V71.6c0-0.9-0.2-1.8-0.6-2.5s-1-1.4-1.8-1.9c-0.6-0.3-1.2-0.6-2-0.8s-1.7-0.4-2.5-0.4v-2.9  l14.4-1l0.6,0.6v6.2h0.3c0.7-0.7,1.6-1.6,2.7-2.6c1-1,2-1.8,2.9-2.4c1-0.7,2.3-1.3,3.8-1.7c1.5-0.5,3.2-0.7,5-0.7  c2.8,0,5.2,0.7,7.2,2.2s3.4,3.3,4.3,5.4c1-1,2-1.9,2.8-2.7s1.9-1.6,3.2-2.5c1.1-0.8,2.4-1.4,3.9-1.8c1.5-0.5,3.2-0.7,5-0.7  c3.6,0,6.5,1.2,8.6,3.6c2.1,2.4,3.2,6,3.2,11v21.9c0,1,0.2,1.9,0.5,2.5c0.3,0.7,0.9,1.2,1.8,1.5c0.7,0.3,1.3,0.5,2,0.6  s1.4,0.2,2.2,0.3L267.2,105.6L267.2,105.6z"
          />
          <path
            fill="#141932"
            d="M310.4,104.8c-1.4,0.5-2.6,0.9-3.6,1.2s-2.2,0.5-3.5,0.5c-2.3,0-4.1-0.5-5.4-1.6c-1.4-1.1-2.2-2.6-2.6-4.6H295  c-1.9,2.1-3.9,3.7-6.1,4.8s-4.8,1.7-7.8,1.7c-3.2,0-5.9-1-8-3S270,99.2,270,96c0-1.7,0.2-3.1,0.7-4.4c0.5-1.3,1.2-2.5,2.1-3.5  c0.7-0.9,1.7-1.7,2.9-2.3c1.2-0.7,2.3-1.2,3.4-1.6c1.3-0.5,4-1.4,8-2.7s6.7-2.4,8.1-3.1v-4.3c0-0.4-0.1-1.1-0.2-2.2  c-0.2-1.1-0.5-2.1-1-3.1c-0.6-1.1-1.4-2-2.5-2.8s-2.6-1.2-4.5-1.2c-1.3,0-2.6,0.2-3.7,0.7s-2,0.9-2.4,1.4c0,0.6,0.1,1.4,0.4,2.6  c0.3,1.1,0.4,2.2,0.4,3.1c0,1-0.5,1.9-1.4,2.8c-0.9,0.8-2.2,1.3-3.8,1.3c-1.5,0-2.5-0.5-3.2-1.5s-1-2.2-1-3.5s0.5-2.6,1.4-3.8  s2.2-2.3,3.7-3.3c1.3-0.8,2.9-1.5,4.8-2.1s3.7-0.9,5.5-0.9c2.4,0,4.6,0.2,6.4,0.5s3.5,1.1,5,2.2c1.5,1.1,2.6,2.5,3.4,4.4  c0.8,1.8,1.2,4.2,1.2,7.1c0,4.2,0,7.8-0.1,11.1c-0.1,3.2-0.1,6.7-0.1,10.5c0,1.1,0.2,2,0.6,2.7s1,1.2,1.8,1.7  c0.4,0.3,1.1,0.4,2.1,0.4c0.9,0,1.9,0,2.9,0v2.6H310.4z M295.2,81.8c-2.5,0.7-4.6,1.4-6.5,2.1s-3.6,1.6-5.2,2.6  c-1.5,1-2.6,2.2-3.4,3.5c-0.8,1.4-1.3,3-1.3,4.8c0,2.4,0.6,4.2,1.9,5.3s2.9,1.7,4.8,1.7c2.1,0,3.9-0.5,5.5-1.5s2.9-2.2,4-3.6  L295.2,81.8z"
          />
          <path
            fill="#141932"
            d="M350.7,101.1c1.2,1.1,2.1,2.4,2.7,3.8c0.7,1.4,1,3.1,1,5.2c0,2.1-0.5,4-1.4,5.8s-2.3,3.4-4.1,4.7  c-1.9,1.4-4.3,2.4-7,3.2c-2.7,0.8-6,1.1-10,1.1c-6.5,0-11.4-1-14.6-2.9s-4.8-4.7-4.8-8.4c0-3,1.2-5.3,3.5-7s5.3-2.6,8.9-2.8v-0.6  c-0.9-0.3-1.8-0.6-2.7-1c-0.9-0.4-1.7-0.9-2.4-1.4c-0.8-0.6-1.4-1.3-1.8-2.2c-0.5-0.8-0.7-1.9-0.7-3.1c0-1.5,0.5-3,1.6-4.3  s2.7-2.4,5-3.3v-0.7c-2.8-1.3-5-3-6.5-5c-1.5-2.1-2.3-4.4-2.3-7.1c0-4.1,1.5-7.4,4.6-10c3-2.6,6.9-3.9,11.7-3.9  c1.3,0,2.6,0.1,3.9,0.4c1.3,0.3,2.6,0.6,3.9,1h15v4.2h-10.1v0.3c1.1,1.3,1.9,2.6,2.4,4.1s0.8,3,0.8,4.4c0,3.8-1.5,7-4.6,9.6  s-6.6,3.9-10.7,3.9h-5.3c-0.7,0.2-1.4,0.7-2.1,1.3s-1.1,1.4-1.1,2.5s0.3,1.9,0.9,2.5s1.4,1,2.2,1.2c0.8,0.2,1.8,0.4,2.9,0.4  c1.1,0.1,2.3,0.1,3.5,0.1c1.3,0,3,0.1,5.3,0.3c2.2,0.1,3.8,0.2,4.7,0.3c1.3,0.1,2.7,0.4,4,1C348.3,99.3,349.5,100.1,350.7,101.1z   M347.2,111.7c0-1.6-0.4-3-1.1-4c-0.8-1-1.9-1.8-3.4-2.1c-0.8-0.1-2.5-0.2-5-0.4c-2.5-0.1-5.5-0.2-8.8-0.2c-3.1,0.6-5.4,1.6-6.7,3.1  s-2,3.3-2,5.6c0,1,0.2,2,0.7,2.9c0.4,0.9,1.2,1.8,2.3,2.6c1,0.8,2.4,1.4,4.1,1.9s3.8,0.7,6.3,0.7c4.3,0,7.6-0.8,10.1-2.5  C346,117.5,347.2,115,347.2,111.7z M338.9,75.6c0-3.4-0.7-6.1-2.1-8.1s-3.3-3-5.8-3c-1.4,0-2.5,0.3-3.5,0.9c-1,0.6-1.8,1.4-2.4,2.4  s-1,2.1-1.2,3.5s-0.4,2.7-0.4,4.2c0,3.2,0.7,5.9,2.1,7.9s3.2,3.1,5.5,3.1c2.5,0,4.4-1,5.8-3C338.2,81.2,338.9,78.7,338.9,75.6z"
          />
          <path
            fill="#141932"
            d="M399.1,104.8c-1.4,0.5-2.6,0.9-3.6,1.2s-2.2,0.5-3.5,0.5c-2.3,0-4.1-0.5-5.4-1.6c-1.4-1.1-2.2-2.6-2.6-4.6h-0.3  c-1.9,2.1-3.9,3.7-6.1,4.8c-2.2,1.1-4.8,1.7-7.8,1.7c-3.2,0-5.9-1-8-3s-3.1-4.6-3.1-7.8c0-1.7,0.2-3.1,0.7-4.4  c0.5-1.3,1.2-2.5,2.1-3.5c0.7-0.9,1.7-1.7,2.9-2.3s2.3-1.2,3.4-1.6c1.3-0.5,4-1.4,8-2.7s6.7-2.4,8.1-3.1v-4.3c0-0.4-0.1-1.1-0.2-2.2  c-0.2-1.1-0.5-2.1-1-3.1c-0.6-1.1-1.4-2-2.5-2.8s-2.6-1.2-4.5-1.2c-1.3,0-2.6,0.2-3.7,0.7c-1.1,0.5-2,0.9-2.4,1.4  c0,0.6,0.1,1.4,0.4,2.6c0.3,1.1,0.4,2.2,0.4,3.1c0,1-0.5,1.9-1.4,2.8c-0.9,0.8-2.2,1.3-3.8,1.3c-1.5,0-2.5-0.5-3.2-1.5s-1-2.2-1-3.5  s0.5-2.6,1.4-3.8s2.2-2.3,3.7-3.3c1.3-0.8,2.9-1.5,4.8-2.1c1.9-0.6,3.7-0.9,5.5-0.9c2.4,0,4.6,0.2,6.4,0.5c1.8,0.3,3.5,1.1,5,2.2  c1.5,1.1,2.6,2.5,3.4,4.4c0.8,1.8,1.2,4.2,1.2,7.1c0,4.2,0,7.8-0.1,11.1c-0.1,3.2-0.1,6.7-0.1,10.5c0,1.1,0.2,2,0.6,2.7  c0.4,0.7,1,1.2,1.8,1.7c0.4,0.3,1.1,0.4,2.1,0.4c0.9,0,1.9,0,2.9,0v2.6H399.1z M383.9,81.8c-2.5,0.7-4.6,1.4-6.5,2.1  c-1.9,0.7-3.6,1.6-5.2,2.6c-1.5,1-2.6,2.2-3.4,3.5c-0.8,1.4-1.3,3-1.3,4.8c0,2.4,0.6,4.2,1.9,5.3c1.3,1.1,2.9,1.7,4.8,1.7  c2.1,0,3.9-0.5,5.5-1.5s2.9-2.2,4-3.6L383.9,81.8z"
          />
          <path
            fill="#141932"
            d="M436.6,105.6h-34.8v-2.3l24.4-37.2h-6c-2.5,0-4.5,0-5.9,0.1s-2.7,0.4-3.9,0.9c-0.8,0.3-1.6,1.2-2.3,2.7  s-1.3,3.5-1.9,5.9h-2.7V62.5h32.9v1.8L411.7,102c1,0,2.2,0.1,3.4,0.1c1.3,0,2.6,0,4.1,0c1.2,0,2.4,0,3.6,0c1.3,0,2.9-0.1,4.9-0.2  c1.2-0.1,2-0.5,2.6-1.2s1.1-1.6,1.4-2.5c0.3-0.8,0.7-2,1.2-3.5s0.9-2.9,1.2-4.3h2.6L436.6,105.6L436.6,105.6z"
          />
          <path
            fill="#141932"
            d="M465.7,105.6h-20.8v-2.9c0.7-0.1,1.4-0.1,2.1-0.2c0.7-0.1,1.3-0.2,1.9-0.3c0.8-0.3,1.4-0.7,1.8-1.4  c0.4-0.7,0.6-1.5,0.6-2.5V71.8c0-0.9-0.2-1.8-0.6-2.5c-0.4-0.8-1-1.4-1.8-1.9c-0.6-0.3-1.4-0.6-2.4-0.9c-1-0.2-2-0.4-2.9-0.5v-2.9  l15.2-1l0.6,0.6v35.1c0,1,0.2,1.9,0.6,2.5c0.4,0.7,1,1.2,1.8,1.5c0.6,0.3,1.3,0.5,1.9,0.6c0.6,0.2,1.3,0.3,2,0.3L465.7,105.6  L465.7,105.6z"
          />
          <path
            fill="#141932"
            d="M517.6,105.6h-21v-2.9c0.7-0.1,1.4-0.1,2.2-0.2c0.8-0.1,1.5-0.2,2-0.3c0.8-0.3,1.4-0.7,1.8-1.4  c0.4-0.7,0.6-1.5,0.6-2.6V76.1c0-3.1-0.7-5.4-2.2-7.1c-1.4-1.7-3.2-2.5-5.3-2.5c-1.5,0-3,0.2-4.2,0.7c-1.3,0.5-2.4,1.1-3.4,1.8  s-1.7,1.4-2.3,2.2s-1,1.4-1.2,1.9v24.7c0,1,0.2,1.8,0.6,2.4c0.4,0.6,1,1.1,1.8,1.5c0.6,0.3,1.3,0.5,2,0.7c0.7,0.1,1.4,0.2,2.2,0.3  v2.9h-21.1v-2.9c0.7-0.1,1.4-0.1,2.1-0.2c0.7-0.1,1.3-0.2,1.9-0.3c0.8-0.3,1.4-0.7,1.8-1.4c0.4-0.7,0.6-1.5,0.6-2.5V71.8  c0-1-0.2-1.9-0.6-2.7c-0.4-0.8-1-1.5-1.8-2c-0.6-0.3-1.2-0.6-2-0.8s-1.6-0.3-2.4-0.3v-2.9l14.3-1l0.6,0.6v6.1h0.2  c0.7-0.7,1.6-1.5,2.6-2.5s2-1.8,3-2.4c1.1-0.7,2.4-1.3,3.8-1.7c1.5-0.5,3.2-0.7,5-0.7c4.1,0,7.2,1.3,9.2,3.9c2,2.6,3,6,3,10.3v22.4  c0,1,0.2,1.8,0.5,2.5c0.3,0.6,0.9,1.1,1.8,1.5c0.7,0.3,1.3,0.5,1.8,0.6s1.2,0.2,2.1,0.3L517.6,105.6L517.6,105.6z"
          />
          <path
            fill="#141932"
            d="M558.5,95.5c-1.8,3.4-4.2,6.2-7.2,8.2s-6.5,3.1-10.4,3.1c-3.5,0-6.5-0.6-9.1-1.8s-4.7-2.9-6.4-5  s-2.9-4.5-3.8-7.3c-0.8-2.8-1.2-5.7-1.2-8.9c0-2.8,0.4-5.5,1.3-8.2c0.9-2.7,2.2-5.1,3.9-7.3c1.7-2.1,3.7-3.7,6.2-5s5.2-1.9,8.3-1.9  s5.8,0.5,8.1,1.5c2.2,1,4.1,2.3,5.5,4.1c1.3,1.6,2.3,3.5,3,5.8c0.7,2.2,1,4.6,1,7.2v2.9h-28.5c0,2.8,0.3,5.4,0.8,7.7  s1.4,4.3,2.6,6.1c1.1,1.7,2.6,3,4.5,4c1.8,1,4,1.5,6.5,1.5c2.6,0,4.8-0.6,6.7-1.8s3.7-3.4,5.4-6.6L558.5,95.5z M548.8,79.1  c0-1.6-0.1-3.4-0.4-5.2c-0.3-1.9-0.7-3.4-1.3-4.7c-0.7-1.3-1.6-2.4-2.7-3.3c-1.2-0.8-2.7-1.3-4.5-1.3c-3.1,0-5.6,1.3-7.6,3.8  s-3.1,6.1-3.3,10.6L548.8,79.1L548.8,79.1z"
          />
          <path
            fill="#141932"
            d="M460.3,45.1c0,1.7-0.5,3.1-1.6,4.3s-2.4,1.9-4,1.9c-1.5,0-2.8-0.6-3.9-1.8s-1.6-2.5-1.6-4c0-1.6,0.5-3,1.6-4.2  s2.4-1.8,3.9-1.8c1.7,0,3,0.6,4.1,1.7C459.7,42.3,460.3,43.6,460.3,45.1z"
          />
        </svg>
        {!hide && (
          <p
            className="mb-0 d-md-block d-none ml-5"
            style={{ lineHeight: "1.5" }}
          >
            Het Grootste Magazine van Nederland
          </p>
        )}
      </div>
      <hr className="headerLine d-md-block d-none" />
    </div>
  );
};

export default Topbar;
