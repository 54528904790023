import { RepositoryFactory } from "../../repository/RepositoryFactory";
var newStraat = RepositoryFactory.get("repo");

export const getStreet =
  (payload, setAddState, onSuccess = () => {}) =>
  async (dispatch) => {
    console.log(payload, "payload");
    try {
      let { data } = await newStraat.getStreet(payload);
      if (data) {
        console.log(data, "data");
        dispatch({
          type: "NEW_STRAAT",
          payload: data,
        });
        dispatch({
          type: "STREET_ERROR_RESPONSE",
          payload: null,
        });
        setAddState(false);
      }
    } catch (e) {
      onSuccess();
      dispatch({
        type: "NEW_STRAAT",
        payload: "data",
      });
      // if (e.response.data.error == "Conflict - duplicate") {
      //   setAddState(false);
      // } else {
      setAddState(true);
      let error = e.response.data.error_id;

      if (error == "NO_RESULTS_FOUND") {
        dispatch({
          type: "STREET_ERROR_RESPONSE",
          payload: { zip: true },
        });
      }
      // }
      console.log("======>", e.response?.data);
    } finally {
      onSuccess();
    }
  };
export const setErrorResponse = () => async (dispatch) => {
  dispatch({
    type: "STREET_ERROR_RESPONSE",
    payload: null,
  });
};
